<template>
<div>
	<CRow>
		<CCol sm="12" md="8">
        <CCard>
          <CCardHeader>
            <h4>Edit KYC Sample Image</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>
            <CForm>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Name</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-input v-model="KycImgName" placeholder="Enter Name"></b-form-input>
                   </b-col>                  
              </b-row><br/>

               <CTextarea
                label="Description"  v-model="KycImgDesc" placeholder="Enter Description"
                horizontal  rows="3"               
               />

              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button-group>
                         <router-link to="/kyc" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="ConfirmSubmit()" variant="primary">Update</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
            </CForm>         
           </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import AccountService from '@/api/AccountService.js';

  export default {    
    data() {
      return { 
          id : this.$route.params.id, 
          KycImgName:'', 
          KycImgDesc:'',
          seen:false,
          msg :'',
          color:''          
        };
    },
    created () {  
        this.getKycImageById(this.id);       
    },
    methods: {

      ConfirmSubmit(){ 
        let params = {}; var validate = [];
        params['name']   = this.KycImgName;       
        params['description'] = this.KycImgDesc;    
       
        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){  //console.log(params); 
              this.updateKYCImage(this.id,params);             
        } else {
           this.msg   = 'Data Not Complete, All field required!'; 
           this.color = 'warning'; this.seen  = true;          
        } 
        setTimeout( () => this.seen=false , 5000);
      },   

      updateKYCImage: function(id,params) { 
          AccountService.updateKYCImage(id,params).then(resp => {   //console.log(resp); 
            if(resp.message=="Success"){
                this.msg   = 'Update KYC Image Data Successfully'; 
                this.color = 'success';  this.seen  = true;                
              } else {
                this.msg   = !resp.data.message ? "Update KYC Image Data Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;               
              }
              setTimeout( () =>  this.seen=false , 5000); 
           }, error => {
            console.log('error');    
          });
      },  
      
      getKycImageById: function(id) { 
          AccountService.getKycImageById(id).then(resp => {  //console.log(resp);          
             this.KycImgName = resp.name;            
             this.KycImgDesc = resp.description;        
          }, error => {
            console.log('error');    
          });
      }, 
      
    }
  }
</script>